import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText 
} from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';

export default function Hero() {
  const painPoints = [
    "Endless tutorials covering edge cases you'll never encounter?",
    "Wasting time on features that don't solve real problems?",
    "Trying to figure out what actually matters in Bicep?",
    "Complex examples that don't reflect daily work?"
  ];

  return (
    <Box
      sx={{
        position: 'relative',
        pt: { xs: 12, sm: 16 },
        pb: { xs: 8, sm: 12 },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: 'url("/assets/images/hero-background.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.15,
          zIndex: 0
        }
      }}
    >
      <Container 
        maxWidth="md" 
        sx={{ 
          position: 'relative',
          zIndex: 1,
          '& > *': {
            textShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }
        }}
      >
        {/* Main Title */}
        <Typography
          component="h1"
          variant="h2"
          sx={{
            fontWeight: 800,
            textAlign: 'center',
            mb: 3,
            color: 'primary.main'
          }}
        >
          BicepDev: Master the 20% of Azure Bicep That Delivers 80% of Results
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="h4"
          align="center"
          sx={{ 
            mb: 6,
            color: 'secondary.main',
            fontWeight: 600
          }}
        >
          Stop Wasting Time Learning Features You'll Never Use
        </Typography>

        {/* Pain Points Section */}
        <Box sx={{ maxWidth: 'md', mx: 'auto', mt: 4 }}>
          <Typography
            variant="h5"
            sx={{
              mb: 3,
              textAlign: 'center',
              fontWeight: 500
            }}
          >
            Are you tired of:
          </Typography>

          <List>
            {painPoints.map((point, index) => (
              <ListItem 
                key={index}
                sx={{
                  py: 1
                }}
              >
                <ListItemIcon>
                  <CancelIcon sx={{ color: 'warning.main' }} />
                </ListItemIcon>
                <ListItemText 
                  primary={point}
                  primaryTypographyProps={{
                    sx: { 
                      fontSize: '1.1rem',
                      fontWeight: 400
                    }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Course Description */}
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography
            variant="h4"
            component="h2"
            sx={{ 
              mb: 3,
              fontWeight: 600,
              color: 'primary.main'
            }}
          >
            Master Production-Ready Bicep in 24 Focused Steps
          </Typography>
          
          <Typography
            variant="h6"
            sx={{
              maxWidth: '800px',
              mx: 'auto',
              color: 'text.secondary',
              lineHeight: 1.6
            }}
          >
            This isn't a comprehensive encyclopedia of Bicep - it's a practical guide 
            focusing on the features and patterns you'll actually use daily as a DevOps 
            or Cloud Engineer.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
} 
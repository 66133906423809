import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

export default function InstructorSection() {
  const credentials = [
    {
      title: "Technical Excellence",
      points: [
        "Microsoft Azure MVP (Infrastructure as Code)",
        "Microsoft Certified Trainer (MCT)",
        "13+ Azure & Cloud Certifications",
        "Proven track record in enterprise Azure implementations"
      ]
    },
    {
      title: "Real-World Experience",
      points: [
        "Enterprise Azure architectures and deployments",
        "Cloud infrastructure automation",
        "DevOps transformation projects",
        "Large-scale Azure implementations"
      ]
    },
    {
      title: "Community Leadership",
      points: [
        "Organizer of Global Azure Poland",
        "Regular speaker at major tech conferences",
        "Infrastructure as Code workshop leader",
        "Active Azure community contributor"
      ]
    }
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'grey.50' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h2"
          align="center"
          sx={{
            mb: 4,
            fontWeight: 700,
            color: 'primary.main'
          }}
        >
          About Your Instructor
        </Typography>

        <Box sx={{ mb: 6, textAlign: 'center' }}>
          <Avatar
            src="/assets/images/3K1A5090_square.jpeg"
            alt="Piotr Wachulec"
            sx={{
              width: 200,
              height: 200,
              mx: 'auto',
              mb: 3,
              border: 3,
              borderColor: 'primary.main',
              boxShadow: 3
            }}
          />
          <Typography
            variant="h4"
            component="h3"
            sx={{ mb: 2, fontWeight: 600 }}
          >
            Piotr Wachulec
          </Typography>
          <Typography
            variant="h6"
            sx={{ mb: 3, color: 'text.secondary' }}
          >
            Microsoft MVP & Microsoft Certified Trainer | Azure Solutions Architect
          </Typography>
          <Typography
            variant="body1"
            sx={{ 
              mb: 6,
              maxWidth: '800px',
              mx: 'auto',
              fontSize: '1.1rem',
              lineHeight: 1.6
            }}
          >
            Since 2018, Piotr has been architecting and implementing Azure solutions across diverse enterprise environments. 
            As a Microsoft <strong>Most Valuable Professional (MVP)</strong> and <strong>Microsoft Certified Trainer (MCT)</strong>, he brings both deep technical 
            expertise and proven teaching ability to complex cloud challenges.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {credentials.map((section, index) => (
            <Grid item key={index} xs={12} md={4}>
              <Card 
                sx={{ 
                  height: '100%',
                  boxShadow: 2,
                  '&:hover': {
                    boxShadow: 4,
                    transform: 'translateY(-4px)',
                  },
                  transition: 'all 0.3s ease-in-out',
                  border: '1px solid',
                  borderColor: 'primary.main',
                }}
              >
                <CardContent sx={{ p: 0 }}>
                  <Box
                    sx={{
                      bgcolor: 'primary.main',
                      py: 2,
                      px: 3,
                      mb: 3,
                      borderBottom: '4px solid',
                      borderColor: 'warning.main',
                      height: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="h3"
                      align="center"
                      sx={{
                        fontWeight: 500,
                        color: 'white',
                        fontSize: '1.5rem',
                      }}
                    >
                      {section.title}
                    </Typography>
                  </Box>

                  <Box sx={{ px: 3, pb: 3 }}>
                    <List>
                      {section.points.map((point, idx) => (
                        <ListItem key={idx} sx={{ py: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <CheckCircleIcon 
                              sx={{ 
                                color: 'primary.main',
                                fontSize: '1rem'
                              }} 
                            />
                          </ListItemIcon>
                          <ListItemText 
                            primary={point}
                            primaryTypographyProps={{
                              sx: { 
                                fontSize: '1rem',
                                lineHeight: 1.5
                              }
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
} 
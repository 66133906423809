import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText 
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

export default function WhatYoullBuild() {
  const learningOutcomes = [
    {
      title: "Enterprise-Grade Infrastructure",
      points: [
        "Multi-environment deployment architectures",
        "Secure, modular infrastructure patterns",
        "Scalable resource organization strategies",
        "Cross-resource dependency management"
      ]
    },
    {
      title: "Reusable Components",
      points: [
        "Maintainable module libraries",
        "Flexible, parameterized templates",
        "Secure configuration patterns",
        "Version-controlled infrastructure"
      ]
    },
    {
      title: "CI/CD-Ready Solutions",
      points: [
        "Registry-integrated modules",
        "Environment-aware deployments",
        "Automated infrastructure rollouts",
        "Consistent resource provisioning"
      ]
    }
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'white' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h2"
          align="center"
          sx={{
            mb: 6,
            fontWeight: 700,
            color: 'primary.main'
          }}
        >
          What You'll Learn to Build
        </Typography>

        <Grid container spacing={4}>
          {learningOutcomes.map((outcome, index) => (
            <Grid item key={index} xs={12} md={4}>
              <Card 
                sx={{ 
                  height: '100%',
                  boxShadow: 2,
                  '&:hover': {
                    boxShadow: 4,
                    transform: 'translateY(-4px)',
                  },
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <CardContent sx={{ p: 0 }}>
                  {/* Title with blue background */}
                  <Box
                    sx={{
                      bgcolor: 'primary.main',
                      py: 2,
                      px: 3,
                      mb: 3,
                      borderBottom: '4px solid',
                      borderColor: 'warning.main',
                      height: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="h3"
                      align="center"
                      sx={{
                        fontWeight: 500,
                        color: 'white',
                        fontSize: '1.5rem',
                      }}
                    >
                      {outcome.title}
                    </Typography>
                  </Box>

                  {/* List content with padding */}
                  <Box sx={{ px: 3, pb: 3 }}>
                    <List>
                      {outcome.points.map((point, idx) => (
                        <ListItem key={idx} sx={{ py: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <CheckCircleIcon 
                              sx={{ 
                                color: 'primary.main',
                                fontSize: '1rem'
                              }} 
                            />
                          </ListItemIcon>
                          <ListItemText 
                            primary={point}
                            primaryTypographyProps={{
                              sx: { 
                                fontSize: '1rem',
                                lineHeight: 1.5
                              }
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
} 
import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText 
} from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';

export default function WhoNeedsThis() {
  const targetAudience = [
    "DevOps Engineers managing Azure infrastructure",
    "Cloud Engineers building enterprise solutions",
    "Platform Teams implementing Infrastructure as Code",
    "Anyone moving from ARM to Bicep"
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="md">
        <Typography
          variant="h3"
          component="h2"
          align="center"
          sx={{
            mb: 3,
            fontWeight: 700,
            color: 'primary.main'
          }}
        >
          Who Needs This Course?
        </Typography>

        <Card
          sx={{
            mt: 4,
            p: 4,
            boxShadow: 2,
            '&:hover': {
              boxShadow: 4,
            },
            transition: 'all 0.3s ease-in-out',
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{
              mb: 3,
              fontWeight: 600,
              color: 'primary.main'
            }}
          >
            Perfect for:
          </Typography>

          <List>
            {targetAudience.map((item, index) => (
              <ListItem 
                key={index}
                sx={{
                  py: 1.5,
                  borderBottom: index !== targetAudience.length - 1 ? '1px solid' : 'none',
                  borderColor: 'rgba(0, 0, 0, 0.08)'
                }}
              >
                <ListItemIcon>
                  <PersonIcon sx={{ color: 'primary.main' }} />
                </ListItemIcon>
                <ListItemText
                  primary={item}
                  primaryTypographyProps={{
                    sx: {
                      fontSize: '1.1rem',
                      fontWeight: 500
                    }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Card>
      </Container>
    </Box>
  );
} 
import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import Benefits from './components/Benefits';
import CourseContent from './components/CourseContent';
import WhoNeedsThis from './components/WhoNeedsThis';
import WhatYoullBuild from './components/WhatYoullBuild';
import Prerequisites from './components/Prerequisites';
import PracticalBenefits from './components/PracticalBenefits';
import Pricing from './components/Pricing';
import Promise from './components/Promise';
import Footer from './components/Footer';
import InstructorSection from './components/InstructorSection';
import TestimonialsSection from './components/TestimonialsSection';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navigation />
      <Hero />
      <Benefits />
      <CourseContent />
      <WhoNeedsThis />
      <WhatYoullBuild />
      <Prerequisites />
      <InstructorSection />
      <TestimonialsSection />
      <PracticalBenefits />
      <Pricing />
      <Promise />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
import React from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Stack, 
  Box,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export default function Navigation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar 
      position="fixed" 
      color="inherit" 
      elevation={0} 
      sx={{ 
        borderBottom: 1, 
        borderColor: 'divider',
        bgcolor: 'rgba(255, 255, 255, 0.95)',
        backdropFilter: 'blur(8px)'
      }}
    >
      <Toolbar sx={{ py: 1 }}>
        {/* Logo/Brand */}
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <img 
            src="/assets/images/logo_napis_600x200.png" 
            alt="Course Logo" 
            style={{ 
              height: 40,
              objectFit: 'contain'
            }} 
          />
        </Box>
        
        {/* Desktop Navigation */}
        {!isMobile ? (
          <Stack direction="row" spacing={2}>
            <Button 
              color="inherit" 
              href="https://titansforge.cloud/" 
              target='_blank'
              sx={{ 
                '&:hover': { 
                  color: 'primary.main',
                  bgcolor: 'transparent'
                }
              }}
            >
              Home
            </Button>
            <Button 
              color="inherit" 
              href="https://titansforge.cloud/privacy" 
              target='_blank'
              sx={{ 
                '&:hover': { 
                  color: 'primary.main',
                  bgcolor: 'transparent'
                }
              }}
            >
              Privacy Policy
            </Button>
            <Button 
              color="inherit" 
              href="https://titansforge.cloud/pricing" 
              target='_blank'
              sx={{ 
                '&:hover': { 
                  color: 'primary.main',
                  bgcolor: 'transparent'
                }
              }}
            >
              Pricing Policy
            </Button>
          </Stack>
        ) : (
          // Mobile Navigation
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} component="a" href="https://titansforge.cloud/" target="_blank">
                Home
              </MenuItem>
              <MenuItem onClick={handleClose} component="a" href="https://titansforge.cloud/privacy" target="_blank">
                Privacy Policy
              </MenuItem>
              <MenuItem onClick={handleClose} component="a" href="https://titansforge.cloud/pricing" target="_blank">
                Pricing Policy
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
} 
import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack 
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

export default function Pricing() {
  const features = [
    "24 practical exercises",
    "Production-ready code examples",
    "Future updates and improvements",
    "Completion certificate"
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="md">
        <Card 
          sx={{ 
            boxShadow: 2,
            '&:hover': {
              boxShadow: 4,
              transform: 'translateY(-4px)',
            },
            transition: 'all 0.3s ease-in-out',
            overflow: 'hidden'
          }}
        >
          <CardContent sx={{ p: 0 }}>
            {/* Title with blue background */}
            <Box
              sx={{
                bgcolor: 'primary.main',
                py: 2,
                px: 3,
                mb: 3,
                borderBottom: '4px solid',
                borderColor: 'warning.main',
                height: '70px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                align="center"
                sx={{
                  fontWeight: 500,
                  color: 'white',
                  fontSize: '1.5rem',
                }}
              >
                Early Access Offer
              </Typography>
            </Box>

            {/* Pricing content */}
            <Box sx={{ px: 4, pb: 4 }}>
              <Stack spacing={3} alignItems="center" sx={{ mb: 4 }}>
                {/* Regular Price */}
                <Typography
                  variant="h6"
                  sx={{
                    color: 'text.secondary',
                    position: 'relative',
                    opacity: 0.85,
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: '50%',
                      height: '2px',
                      background: 'rgba(0, 0, 0, 0.3)',
                      transform: 'rotate(-8deg)',
                    }
                  }}
                >
                  Regular Price: €499
                </Typography>

                {/* Launch Price */}
                <Typography
                  variant="h3"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 700,
                    letterSpacing: '-0.5px'
                  }}
                >
                  Launch Price: €399
                </Typography>

                {/* Promo Code */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    bgcolor: 'warning.main',
                    color: 'warning.contrastText',
                    py: 1,
                    px: 3,
                    borderRadius: 2,
                    fontWeight: 600
                  }}
                >
                  with code TITANPIONEER
                </Typography>

                {/* Price Note */}
                <Typography
                  variant="body2"
                  align="center"
                  sx={{
                    color: 'text.secondary',
                    fontStyle: 'italic',
                    maxWidth: '400px',
                    mt: 1,
                    whiteSpace: 'pre-line'
                  }}
                >
                  Note:
                  {'\n'}This special pricing is available only until the course launch in mid-December.
                  {'\n'}All prices are net (excluding VAT).
                  {'\n'}VAT will be added based on your location at checkout.
                </Typography>
              </Stack>

              {/* Divider text */}
              <Typography
                variant="h6"
                align="center"
                sx={{
                  mb: 3,
                  fontWeight: 600,
                  color: 'text.primary'
                }}
              >
                Includes:
              </Typography>

              {/* Features list */}
              <List>
                {features.map((feature, index) => (
                  <ListItem 
                    key={index} 
                    sx={{ 
                      py: 1.5,
                      borderBottom: index !== features.length - 1 ? '1px solid rgba(0, 0, 0, 0.08)' : 'none'
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <CheckCircleIcon 
                        sx={{ 
                          color: 'primary.main',
                          fontSize: '1.1rem'
                        }} 
                      />
                    </ListItemIcon>
                    <ListItemText 
                      primary={feature}
                      primaryTypographyProps={{
                        sx: { 
                          fontSize: '1.1rem',
                          fontWeight: 500,
                          lineHeight: 1.5
                        }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
} 
import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText 
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

export default function CourseContent() {
  const modules = [
    {
      title: "Foundation (E01-E08)",
      points: [
        "Setting up your development environment",
        "Creating and parametrizing templates",
        "Understanding deployment modes",
        "Managing resource dependencies"
      ]
    },
    {
      title: "Advanced Concepts (E09-E16)",
      points: [
        "Working with child resources",
        "Implementing loops and conditions",
        "Creating reusable modules",
        "Managing complex deployments"
      ]
    },
    {
      title: "Enterprise Features (E17-E24)",
      points: [
        "Secure configuration management",
        "Container registry integration",
        "Multi-environment deployments",
        "Production best practices"
      ]
    }
  ];

  const overviewModules = [
    {
      title: "Essential Foundation",
      points: [
        "Development environment setup that works",
        "Template fundamentals you'll use daily",
        "Core deployment patterns",
        "Critical resource dependencies"
      ]
    },
    {
      title: "Common Advanced Patterns",
      points: [
        "Practical child resource management",
        "Real-world loops and conditions",
        "Module patterns that scale",
        "Multi-resource deployments"
      ]
    },
    {
      title: "Enterprise Essentials",
      points: [
        "Secure configuration (that actually works)",
        "Registry integration for teams",
        "Multi-environment strategies",
        "Battle-tested best practices"
      ]
    }
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'white' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h2"
          align="center"
          sx={{
            mb: 2,
            fontWeight: 700,
            color: 'primary.main'
          }}
        >
          Course Content
        </Typography>

        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          sx={{ 
            mb: 6,
            maxWidth: '800px',
            mx: 'auto',
            fontStyle: 'italic'
          }}
        >
          Note: Every module in this course is crafted around real-world usage patterns. 
          We focus on what works in production, not theoretical possibilities.
        </Typography>

        <Typography
          variant="h4"
          component="h3"
          align="center"
          sx={{
            mb: 4,
            fontWeight: 600,
            color: 'primary.main'
          }}
        >
          Module Overview
        </Typography>

        <Grid container spacing={4} sx={{ mb: 6 }}>
          {overviewModules.map((module, index) => (
            <Grid item key={index} xs={12} md={4}>
              <Card 
                sx={{ 
                  height: '100%',
                  boxShadow: 2,
                  '&:hover': {
                    boxShadow: 4,
                    transform: 'translateY(-4px)',
                  },
                  transition: 'all 0.3s ease-in-out',
                  border: '1px solid',
                  borderColor: 'primary.main',
                }}
              >
                <CardContent sx={{ p: 0 }}>
                  <Box
                    sx={{
                      bgcolor: 'primary.main',
                      py: 2,
                      px: 3,
                      mb: 3,
                      borderBottom: '4px solid',
                      borderColor: 'warning.main',
                      height: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="h3"
                      align="center"
                      sx={{
                        fontWeight: 500,
                        color: 'white',
                        fontSize: '1.5rem',
                      }}
                    >
                      {module.title}
                    </Typography>
                  </Box>

                  <Box sx={{ px: 3, pb: 3 }}>
                    <List>
                      {module.points.map((point, idx) => (
                        <ListItem key={idx} sx={{ py: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <CheckCircleIcon 
                              sx={{ 
                                color: 'primary.main',
                                fontSize: '1rem'
                              }} 
                            />
                          </ListItemIcon>
                          <ListItemText 
                            primary={point}
                            primaryTypographyProps={{
                              sx: { 
                                fontSize: '1rem',
                                lineHeight: 1.5
                              }
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography
          variant="h4"
          component="h3"
          align="center"
          sx={{
            mb: 4,
            fontWeight: 600,
            color: 'primary.main'
          }}
        >
          Detailed Exercise Breakdown
        </Typography>

        <Grid container spacing={4}>
          {modules.map((module, index) => (
            <Grid item key={index} xs={12} md={4}>
              <Card 
                sx={{ 
                  height: '100%',
                  boxShadow: 2,
                  '&:hover': {
                    boxShadow: 4,
                    transform: 'translateY(-4px)',
                  },
                  transition: 'all 0.3s ease-in-out',
                  border: '1px solid',
                  borderColor: 'primary.main',
                }}
              >
                <CardContent sx={{ p: 0 }}>
                  <Box
                    sx={{
                      bgcolor: 'primary.main',
                      py: 2,
                      px: 3,
                      mb: 3,
                      borderBottom: '4px solid',
                      borderColor: 'warning.main',
                      height: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="h3"
                      align="center"
                      sx={{
                        fontWeight: 500,
                        color: 'white',
                        fontSize: '1.5rem',
                      }}
                    >
                      {module.title}
                    </Typography>
                  </Box>

                  <Box sx={{ px: 3, pb: 3 }}>
                    <List>
                      {module.points.map((point, idx) => (
                        <ListItem key={idx} sx={{ py: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <CheckCircleIcon 
                              sx={{ 
                                color: 'primary.main',
                                fontSize: '1rem'
                              }} 
                            />
                          </ListItemIcon>
                          <ListItemText 
                            primary={point}
                            primaryTypographyProps={{
                              sx: { 
                                fontSize: '1rem',
                                lineHeight: 1.5
                              }
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
} 
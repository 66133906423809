import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText 
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

export default function Prerequisites() {
  const prerequisites = [
    "Basic Azure knowledge",
    "Familiarity with cloud concepts",
    "VS Code experience",
    "PowerShell basics"
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="md">
        <Card 
          sx={{ 
            boxShadow: 2,
            '&:hover': {
              boxShadow: 4,
              transform: 'translateY(-4px)',
            },
            transition: 'all 0.3s ease-in-out'
          }}
        >
          <CardContent sx={{ p: 0 }}>
            {/* Title with blue background */}
            <Box
              sx={{
                bgcolor: 'primary.main',
                py: 2,
                px: 3,
                mb: 3,
                borderBottom: '4px solid',
                borderColor: 'warning.main',
                height: '70px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                align="center"
                sx={{
                  fontWeight: 500,
                  color: 'white',
                  fontSize: '1.5rem',
                }}
              >
                Prerequisites
              </Typography>
            </Box>

            {/* List content with padding */}
            <Box sx={{ px: 4, pb: 4 }}>
              <List>
                {prerequisites.map((prerequisite, index) => (
                  <ListItem 
                    key={index} 
                    sx={{ 
                      py: 1.5,
                      borderBottom: index !== prerequisites.length - 1 ? '1px solid rgba(0, 0, 0, 0.08)' : 'none'
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <CheckCircleIcon 
                        sx={{ 
                          color: 'primary.main',
                          fontSize: '1.1rem'
                        }} 
                      />
                    </ListItemIcon>
                    <ListItemText 
                      primary={prerequisite}
                      primaryTypographyProps={{
                        sx: { 
                          fontSize: '1.1rem',
                          fontWeight: 500,
                          lineHeight: 1.5
                        }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
} 
import React from 'react';
import { 
  Box, 
  Container, 
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar
} from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

export default function TestimonialsSection() {
  const testimonials = [
    {
      quote: "Piotrek is a great specialist and a very good spotter. In addition to superb technical skills, he is distinguished by proactivity, responsibility, fear for good relations in the company and the attitude of \"if I don't know something, but I need it, I will learn it right away\".",
      author: "Tomasz Szymański",
      title: "Project Manager",
      image: "/assets/images/Tomasz_Szymanski_photo.jpg"
    },
    {
      quote: "Peter has a very professional approach, the workshop he prepared was at the highest level, it was a nicely spent time with a good dose of knowledge.",
      author: "Kamil Maciejewski",
      title: "Cloud Architect",
      image: null
    }
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'white' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h2"
          align="center"
          sx={{
            mb: 6,
            fontWeight: 700,
            color: 'primary.main'
          }}
        >
          What Others Say
        </Typography>

        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item key={index} xs={12} md={6}>
              <Card
                sx={{
                  height: '100%',
                  boxShadow: 2,
                  '&:hover': {
                    boxShadow: 4,
                    transform: 'translateY(-4px)',
                  },
                  transition: 'all 0.3s ease-in-out',
                  border: '1px solid',
                  borderColor: 'primary.main',
                  position: 'relative',
                  overflow: 'visible'
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <FormatQuoteIcon 
                    sx={{ 
                      position: 'absolute',
                      top: -20,
                      left: 20,
                      fontSize: '3rem',
                      color: 'primary.main',
                      bgcolor: 'white',
                      borderRadius: '50%',
                      p: 1
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 3,
                      fontSize: '1.1rem',
                      lineHeight: 1.6,
                      fontStyle: 'italic'
                    }}
                  >
                    {testimonial.quote}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {testimonial.image && (
                      <Avatar
                        src={testimonial.image}
                        alt={testimonial.author}
                        sx={{
                          width: 60,
                          height: 60,
                          mr: 2,
                          border: 1,
                          borderColor: 'primary.main'
                        }}
                      />
                    )}
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          color: 'primary.main'
                        }}
                      >
                        {testimonial.author}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'text.secondary'
                        }}
                      >
                        {testimonial.title}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
} 
import React from 'react';
import { 
  Box, 
  Container, 
  Typography,
  Stack,
  Button 
} from '@mui/material';

export default function Promise() {
  return (
    <Box 
      sx={{ 
        py: 4,
        bgcolor: 'secondary.main',
        color: 'white'
      }}
    >
      <Container maxWidth="lg">
        <Stack 
          spacing={4} 
          alignItems="center"
        >
          {/* Promise Section */}
          <Box sx={{ textAlign: 'center', maxWidth: '900px' }}>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                mb: 2,
                fontWeight: 700
              }}
            >
              Our Promise
            </Typography>

            <Typography
              variant="h5"
              sx={{
                fontWeight: 400,
                lineHeight: 1.6
              }}
            >
              If you complete the exercises and don't find yourself using what you've learned in your daily work, we'll refund your investment 100%. We honor refunds immediately and appreciate your feedback to keep improving.
            </Typography>
          </Box>

          {/* CTA Button */}
          <Button
            variant="contained"
            color="warning"
            size="large"
            href="https://buy.stripe.com/9AQ03z43Pg4g9tSbII?prefilled_promo_code=TITANPIONEER"
            target="_blank"
            sx={{
              width: '350px',
              py: 2,
              fontSize: '1.1rem',
              fontWeight: 600,
              textTransform: 'none',
              borderRadius: '100px'
            }}
          >
            Begin Your Evolution »
          </Button>

          {/* Cohort Info */}
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 500,
                mb: 1
              }}
            >
              First Module Available Mid-December
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                fontStyle: 'italic'
              }}
            >
              Launch pricing ends with course release
            </Typography>
          </Box>

          {/* Footer Note */}
          <Typography
            variant="h6"
            sx={{
              width: '100%',
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              pt: 3,
              fontWeight: 400,
              fontStyle: 'italic',
              textAlign: 'center'
            }}
          >
            Part of the TitansForge Azure Excellence program, focusing on skills you'll actually use.
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
} 
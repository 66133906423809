import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button,
  Link,
  Stack
} from '@mui/material';

export default function Footer() {
  const footerLinks = {
    Product: [
      { text: 'Pricing', url: 'https://titansforge.cloud/pricing' }
    ],
    TitansForge: [
      { text: 'Vision', url: 'https://titansforge.cloud/vision' },
      { text: 'Mission', url: 'https://titansforge.cloud/mission' },
      { text: 'Values', url: 'https://titansforge.cloud/values' },
      { text: 'Founder', url: 'https://titansforge.cloud/founder' }
    ],
    Legal: [
      { text: 'Terms', url: 'https://titansforge.cloud/tos' },
      { text: 'Privacy', url: 'https://titansforge.cloud/privacy' },
      { text: 'Contact', url: 'mailto:piotr@titansforge.cloud' }
    ]
  };

  const handleSubscribeClick = () => {
    // @ts-ignore
    if (window.ml) {
      // @ts-ignore
      window.ml('show', 'hm14yM', true);
    }
  };

  return (
    <Box sx={{ bgcolor: 'black', color: 'white', pt: 8, pb: 4 }}>
      <Container maxWidth="lg">
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 4
          }}
        >
          {/* Newsletter Section - Left aligned */}
          <Box sx={{ maxWidth: '450px' }}>
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>
              Join the Forge
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, color: 'grey.400' }}>
              By subscribing, you're taking the first step towards becoming an Azure Titan. Expect:
            </Typography>
            <Box component="ul" sx={{ mb: 3, color: 'grey.400' }}>
              <Typography component="li" variant="body1">Cutting-edge Azure insights</Typography>
              <Typography component="li" variant="body1">Strategies for mental and professional growth</Typography>
              <Typography component="li" variant="body1">Exclusive community events and challenges</Typography>
              <Typography component="li" variant="body1">A safe space to learn, fail, and rise stronger</Typography>
            </Box>
            <Button 
              variant="contained"
              color="primary"
              onClick={handleSubscribeClick}
              sx={{ 
                borderRadius: 2,
                px: 3,
                py: 1,
                fontSize: '1.1rem'
              }}
            >
              Forge My Azure Future »
            </Button>
          </Box>

          {/* Links Sections - Right aligned */}
          <Box sx={{ 
            display: 'flex', 
            gap: 4,
            flexWrap: 'wrap'
          }}>
            {Object.entries(footerLinks).map(([category, links]) => (
              <Box key={category} sx={{ minWidth: '160px' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {category}
                </Typography>
                <Stack spacing={1}>
                  {Array.isArray(links) ? 
                    links.map((link) => (
                      <Link
                        key={typeof link === 'string' ? link : link.text}
                        href={typeof link === 'string' ? "#" : link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="hover"
                        sx={{ 
                          color: 'grey.400',
                          '&:hover': { color: 'white' }
                        }}
                      >
                        {typeof link === 'string' ? link : link.text}
                      </Link>
                    )) : null}
                </Stack>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Bottom Section */}
        <Box sx={{ 
          mt: 8, 
          pt: 3, 
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2
        }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography variant="body2" sx={{ color: 'grey.400' }}>
              Copyright © TitansForge 2024
            </Typography>
            <Link 
              href="https://titansforge.cloud/privacy" 
              target="_blank"
              rel="noopener noreferrer"
              underline="hover" 
              sx={{ color: 'grey.400', '&:hover': { color: 'white' } }}
            >
              Privacy Policy
            </Link>
            <Link 
              href="https://titansforge.cloud/tos"
              target="_blank"
              rel="noopener noreferrer"
              underline="hover" 
              sx={{ color: 'grey.400', '&:hover': { color: 'white' } }}
            >
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
} 
import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText 
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

export default function Benefits() {
  const benefits = [
    {
      title: "Focused on Daily Reality",
      points: [
        "Learn the 20% of Bicep features that solve 80% of real infrastructure challenges",
        "Skip rarely-used features that don't impact your daily work",
        "Focus on patterns you'll actually implement",
        "Master the core skills that matter"
      ]
    },
    {
      title: "Production-First Approach",
      points: [
        "Build real-world infrastructure used in enterprise",
        "Learn patterns that actually get deployed",
        "Focus on maintainable, reliable solutions",
        "Skip theoretical scenarios"
      ]
    },
    {
      title: "Practical Progression",
      points: [
        "Start with essential fundamentals",
        "Build up to commonly-used advanced features",
        "Learn what experienced engineers use daily",
        "Focus on reproducible patterns"
      ]
    }
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h2"
          align="center"
          sx={{
            mb: 6,
            fontWeight: 700,
            color: 'primary.main'
          }}
        >
          What Makes This Course Different?
        </Typography>

        <Grid container spacing={4}>
          {benefits.map((benefit, index) => (
            <Grid item key={index} xs={12} md={4}>
              <Card 
                sx={{ 
                  height: '100%',
                  boxShadow: 2,
                  '&:hover': {
                    boxShadow: 4,
                    transform: 'translateY(-4px)',
                  },
                  transition: 'all 0.3s ease-in-out'
                }}
              >
                <CardContent sx={{ p: 0 }}>
                  <Box
                    sx={{
                      bgcolor: 'primary.main',
                      py: 2,
                      px: 3,
                      mb: 3,
                      borderBottom: '4px solid',
                      borderColor: 'warning.main',
                      height: '70px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="h3"
                      align="center"
                      sx={{
                        fontWeight: 500,
                        color: 'white',
                        fontSize: '1.5rem',
                      }}
                    >
                      {benefit.title}
                    </Typography>
                  </Box>

                  <Box sx={{ px: 3, pb: 3 }}>
                    <List>
                      {benefit.points.map((point, idx) => (
                        <ListItem key={idx} sx={{ py: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <CheckCircleIcon 
                              sx={{ 
                                color: 'primary.main',
                                fontSize: '1rem'
                              }} 
                            />
                          </ListItemIcon>
                          <ListItemText 
                            primary={point}
                            primaryTypographyProps={{
                              sx: { 
                                fontSize: '1rem',
                                lineHeight: 1.5
                              }
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
} 